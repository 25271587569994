import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import b from "../../image/parents/b.jpg";
import c from "../../image/parents/c.jpg";
import d from "../../image/parents/d.jpg";
import e from "../../image/parents/e.jpg";
// import f from "../../image/parents/f.jpg";
import s1 from "../../image/final/s1.png";
import s2 from "../../image/final/s2.png";
import s3 from "../../image/final/s3.png";
import paTopIcon from "../../image/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
            />
            Parents' Voice
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={b}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
              />
            </div>
            <div className={`voice_text`}>
              <p style={{ marginTop: "1rem" }}>
                <b>Rani Raghav (IV-A)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My name is Rani Raghav, mother of Daksh Raghav. My child studies
                in Hermann Gmeiner School in 4th standard. From the starting he
                studies in the same school. This is the best school for the
                start-up of students, because if the foundation years or we the
                first step of child is good or strong then all the stairs would
                be easy to climb. The school has professional and trained
                faculties. The Staff is very cordial in nature with parents and
                they praise and support the parents and encourage children.
                School provides many facilities are provide for the students
                just like a big playground for gaming activities, library for
                reading the different books, swings for children enjoyment. They
                outing the students for picnic and for fun. The school also held
                many activities just like science exhibition and many more. The
                fee is also affordable for parents, means we bear and afford
                their fees. The school and teachers are superb and excellent at
                every level. I’m very happy and satisfied!
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Jeet Singh (V)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am Jeet Singh and my child Nandish is a student of Grade V.
                The school teachers are very supportive and experienced. They
                explain the concepts and teach students using various
                methodologies. School has good infrastructure and is Eco
                friendly. The school conducts various activities to enhance
                learnings of students. I like the school very much.
              </p>
            </div>
            <div className="parenttimage">
              <img
                src={c}
                style={{
                  width: "10rem",
                  height: "10rem",

                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "3rem" }}>
            <div className="parentimage">
              <img
                src={d}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b> Rahul (LKG)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am Rahul father of Shivansh Bhadana, Class LKG. It is the
                dream of every parent for their children to make their place in
                the society. In todays time, a lot of resources are needed to
                fulfil this dream, all these resources are available in Hermann
                Gmeiner School. The first stage in school depends on the
                teacher. If I talk about KG class, human behaviour provides a
                lot of satisfaction from the teacher&#39;s teaching, we are
                happy, our child is studying in a teacher and school where
                humanity is seen first. From participating in various activities
                to studies, the coordination is seen in your school, which is
                very important for children in elementary education.With the
                elementary education of the children, your school organization
                has led the children towards a bright future, I would like to
                bow down to all the teachers and other staff of the school and
                their aim is to educate the children, make them a good person,
                not just to earn money.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b> Naresh (V)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I’m Naresh Father of Master Dev, Class 5 th of Hermann Gmeiner
                School who is doing excellent in all fields especially giving a
                lot of exposure to children. All activities are nicely planned
                and organised and the teachers take efforts to make the learning
                environment better. I am grateful to all teachers and Principal.
                I feel that my decision was right in sending my child to Hermann
                Gmeiner School and totally impressed with co-curricular
                activities organised by the school this has helped my child to
                develop confidence and sportsmen ship.
              </p>
            </div>
            <div className="parenttimage">
              <img
                src={e}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          {/*  */}

          {/* students' voice */}
          <div className={`f-2 f-500 mt-5 `} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",
              }}
            />
            Students' Voice
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={s1}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Mayank (IV)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My name is Mayank. I study in class 4 th .I read in SOS HERMANN
                GMEINER SCHOOL, ANNAGPUR, FARIDABAD. My school environment is
                very good. There is cleanliness all around my school. The
                teaching method of our school teacher is very impressive as the
                teacher using practical method: LEARNING THROUGH ACTIVITY. In
                our school, we have lot of activity to do, especially on
                occasion of festivals.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Vansh (IV ) </b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My name is Vansh. I study in class 4 th .I read in SOS HERMANN
                GMEINER SCHOOL, ANNAGPUR, FARIDABAD. My school is very big. Our
                school is well maintained and special attention given on the
                cleanliness. Every teacher of my school is well trained. They
                emphasis on activity based learning. In our school, we celebrate
                festivals by doing different activities.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={s2}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>

          <div className="parents__voice">
            <div className="parentimage">
              <img
                src={s3}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Bhawana (v)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My name is Bhawana. I study in class 5 th .I read in SOS HERMANN
                GMEINER SCHOOL, ANNAGPUR, FARIDABAD. I am going to tell
                something about my school. My school is from class Nursery to
                class -V. Our school teacher‘s teaching method is very good.
                They teach in very polite manner. And always ready to clear our
                doubts. Our sports teacher makes us to learn new games. In our
                school, there is big playground to play and do sports
                activities. We have computer lab and also library in our school.
                Our school environment is very good for learning. I love my
                school and my teachers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;
