import axios from "axios";

export const getStudentTCData = async () => {
  try {
    const response = await axios.get("/api/std_tc_list.php?school_code=HGPA");
    return response.data.student_tcinfo;
  } catch (error) {
    console.log(error);
  }
};
