import pre_primary from "./image/pre-primary.jpg";
import primary from "./image/primary.jpg";
import secondary from "./image/secondary.jpg";
import sr_secondary from "./image/sr_secondary.jpg";

//alumni
import nikhel from "./image/nikhel.jpg";
import ankita from "./image/ankita.jpg";
import sandeep from "./image/sandeep.jpg";
import bharat_bansal from "./image/bharat_bansal.jpg";
import subhadeep from "./image/subhadeep.jpg";
import karan from "./image/karan.jpg";
//
import JinsiPhoto from "./image/newImg/Jinsi-picture.png";
import SumantaPhoto from "./image/newImg/Sumanta.jpg";
import DebarotiPhoto from "./image/debaroti.png";
import RameshKumar from "./image/ramesh.png";

//
import result_10 from "./image/result-10.jpg";
import result_10_1 from "./image/result-10-1.jpg";
import result_10_2 from "./image/result-10-2.jpg";
import result_10_3 from "./image/result-10-3.jpg";
import result_12_1 from "./image/result-12-1.jpg";
import result_12_2 from "./image/result-12-2.jpg";
import result_12_3 from "./image/result-12-3.jpg";

//

import laboratories from "./image/laboratories.jpg";
import library from "./image/library.jpg";
//
import life_skill_1 from "./image/life_skill_1.jpg";
import life_skill_2 from "./image/life_skill_2.jpg";
import vocational_1 from "./image/vocational_1.jpg";
import vocational_2 from "./image/vocational_2.jpg";
import skill_based from "./image/skill_based.jpg";
import skill_based_2 from "./image/skill_based_2.jpg";
import annual_1 from "./image/final/ChildrensDay.jpg";
import annual_2 from "./image/annual_2.jpg";
import annual_3 from "./image/annual_3.jpg";
import sports_club from "./image/final/HealthClub2.jpg";
import environment_club from "./image/environment_club.jpg";
import expression_club from "./image/expression_club.jpg";
import student_council_1 from "./image/student_council_1.jpg";
import student_council_2 from "./image/student_council_2.jpg";
import student_council_3 from "./image/student_council_3.jpg";
import ncc_1 from "./image/ncc_1.jpg";
import school_band_1 from "./image/school_band_1.jpg";
import scouts_1 from "./image/scouts_1.jpg";
import bus_1 from "./image/bus_1.jpg";
import award_1 from "./image/award_1.jpg";
import award_2 from "./image/award_2.jpg";
import award_3 from "./image/award_3.jpg";
import award_4 from "./image/award_4.jpg";
import award_5 from "./image/award_5.jpg";
import award_6 from "./image/award_6.jpg";
import award_7 from "./image/award_7.jpg";
import award_8 from "./image/award_8.jpg";
import award_9 from "./image/award_9.jpg";
import award_10 from "./image/award_10.jpg";
import pre_primary_1 from "./image/pre_primary_1.jpg";
import pre_primary_2 from "./image/pre_primary_2.jpg";
import primary_1 from "./image/primary_1.jpg";
import secondary_1 from "./image/secondary_1.jpg";
import secondary_2 from "./image/secondary_2.jpg";
import sr_secondary_1_1 from "./image/sr_secondary_1_1.jpg";
import sr_secondary_2 from "./image/sr_secondary_2.jpg";
import career_1 from "./image/career_1.jpg";
import career_2 from "./image/career_2.jpg";
import career_4 from "./image/career_4.jpg";
import career_5 from "./image/career_5.jpg";
import sports_1 from "./image/sports_1.jpg";
import sports_3 from "./image/sports_3.jpg";
import arts_club from "./image/arts_club.jpg";
import playground_1 from "./image/playground_1.jpg";
import vision from "./image/vision.jpg";
import mission from "./image/mission.jpg";
import anti_bullying from "./image/anti_bullying.jpg";
import health_1 from "./image/health_1.jpg";
import health_2 from "./image/health_2.jpg";
import value_club from "./image/value_club.jpg";
import social_impact from "./image/social_impact.JPG";
import gender_sensitivity from "./image/gender_sensitivity.jpg";
import english_club from "./image/english_club.jpg";
import budding_1 from "./image/budding_1.jpg";
import budding_2 from "./image/budding_2.jpg";
import sdmc from "./image/sdmc.jpg";
import sdmc_2 from "./image/sdmc_2.jpg";
import house_system from "./image/house_system.jpg";
import trip_1 from "./image/trip_1.jpg";
import trip_cover from "./image/trip_cover.jpg";
import trip_cover_1 from "./image/trip_cover_1.jpg";
import trip_4 from "./image/trip_4.jpg";
import primary_curriculum from "./image/primary_curriculum.jpg";
import interactive from "./image/interactive.jpg";
import happy_classroom from "./image/happy_classroom.jpeg";
import experimental_learning from "./image/experimental_learning.jpg";
import spacious_classroom from "./image/spacious_classroom.jpg";
import spacious_classroom_2 from "./image/spacious_classroom_2.jpg";
import computer from "./image/Computer.jpg";
import libraryinfra from "./image/final/library.jpg";
import stem_lab from "./image/stem_lab.jpg";
import happy from "./image/happyClassroms.jpg";
import stem_lab_2 from "./image/stem_lab_2.jpg";
import computer_lab_1 from "./image/computer_lab_1.jpg";
import computer_lab_2 from "./image/computer_lab_2.jpg";
import laboratories_2 from "./image/laboratories_2.jpg";
import home_science from "./image/home_science.jpg";
import home_science_2 from "./image/home_science_2.jpg";
import arts from "./image/arts.jpg";
import arts_2 from "./image/arts_2.jpg";
import library_2 from "./image/library_2.jpg";
import cultural from "./image/cultural.jpg";
import cultural_2 from "./image/cultural_2.jpg";
import cultural_3 from "./image/cultural_3.jpg";
import cultural_4 from "./image/cultural_4.jpg";
import bio_lab from "./image/bio_lab.jpg";
import chem_lab from "./image/chem_lab.jpg";
import phy_lab from "./image/phy_lab.jpg";
import infrastructure_1 from "./image/infrastructure.jpg";
import faculty_1 from "./image/newImg/1.png";
import faculty_2 from "./image/newImg/2.png";
import faculty_3 from "./image/newImg/3.png";
import faculty_4 from "./image/newImg/4.png";
import faculty_5 from "./image/newImg/5.png";
import faculty_6 from "./image/newImg/6.png";
import aa from "./image/newImg/aa.jpeg";
import a from "./image/newImg/a.jpg";
import b from "./image/newImg/b.jpg";
import c from "./image/newImg/c.jpg";
import d from "./image/newImg/d.jpg";
import e from "./image/newImg/e.jpg";

import pre1 from "./image/newImg/Preprimary (1).jpeg";
import pre2 from "./image/newImg/Preprimary (2).jpeg";

import red from "./image/final/JyotiHouse.jpg";
import blue from "./image/final/JagritiHouse.jpg";
import yellow from "./image/final/SmritiHouse.jpg";
import green from "./image/final/PragatiHouse.jpg";

import expression from "./image/newImg/expression.png";
import ba from "./image/newImg/ba.png";

import media1 from "./image/newImg/media/2.jpg";
import media2 from "./image/newImg/media/4.jpg";
import media3 from "./image/newImg/media/5.jpg";
import media4 from "./image/newImg/media/6.jpg";
import media5 from "./image/newImg/media/7.jpg";
import media from "./image/newImg/media/8.jpg";
import media6 from "./image/newImg/media/9.jpg";
import media7 from "./image/newImg/media/10.jpg";
import media8 from "./image/newImg/media/11.jpg";
import media9 from "./image/newImg/media/12.jpg";
import media10 from "./image/newImg/media/13.jpg";

import sports_club_icon from "./image/clubs/sportsClubIcon.png";
import anti_bullying_icon from "./image/clubs/antiBullyingIcon.png";
import environment_club_icon from "./image/clubs/environmentClubIcon.png";
import health_club_icon from "./image/clubs/healthClubIcon.png";
import value_club_icon from "./image/clubs/valueClubIcon.png";
import social_impact_club_icon from "./image/clubs/socialImpactClub.png";
import gender_club from "./image/clubs/genderClub.png";
import english_club_icon from "./image/clubs/englishClub.png";

//
import orientation_1 from "./image/orientation_1.jpg";
import orientation_2 from "./image/orientation_2.jpg";
import orientation_3 from "./image/orientation_3.jpg";
import orientation_4 from "./image/orientation_4.jpg";
import book from "./image/book.jpg";
import waste from "./image/waste.jpg";
import waste_2 from "./image/waste_2.jpg";
import paper from "./image/paper.jpg";
import republic_1 from "./image/final/PoemRecitation.jpg";
import republic_2 from "./image/republic_2.jpg";
import republic_3 from "./image/republic_3.jpg";
import covid from "./image/covid.jpg";
import mother from "./image/mother.jpg";
import first_aid from "./image/first_aid.jpg";
import basant_1 from "./image/basant_1.jpg";
import basant_2 from "./image/basant_2.jpg";
import pareeksha from "./image/pareeksha.jpg";
import pareeksha_2 from "./image/pareeksha_2.jpg";
import heritage from "./image/heritage.jpg";
import farewell from "./image/farewell.jpg";
import chess from "./image/final/chess.jpg";
import farewell_2 from "./image/farewell_2.jpg";
import environment from "./image/environment.jpg";
import fashion from "./image/fashion.jpg";
import fire from "./image/fire.jpg";
import donation_1 from "./image/donation_1.jpg";
import donation_2 from "./image/donation_2.jpg";

import { FaSchool } from "react-icons/fa";

import christmas from "../src/image/Events/christmas.jpg";
import gandhijayanti from "../src/image/Events/gandhijayanti.jpg";
import gk from "../src/image/Events/gk.jpg";
import independence from "../src/image/Events/independence.jpg";
import janmashtmi from "../src/image/Events/janmashtmi.jpg";
import motherday from "../src/image/Events/motherday.jpg";
import ramlila from "../src/image/Events/ramlila.jpg";
import republic from "../src/image/Events/republic.jpg";

import enviornment from "./image/enviornment.jpeg";
import aid from "./image/aid.jpg";
import social_club from "./image/social_club.JPG";
//
export const sidebar_data_vision = [
  {
    title: "Vision & Mission",
    navigation: "vision-and-mission",
  },
  {
    title: "Message From Leadership",
    navigation: "message",
  },
  {
    title: "Academics & Curriculum",
    navigation: "nep",
  },
  {
    title: "Beyond Curriculum",
    navigation: "house-clubs",
  },
  {
    title: "Sports & Fitness",
    navigation: "sports",
  },
  {
    title: "Faculty & Staff",
    navigation: "faculty-staff",
  },
  {
    title: "Events & Happenings",
    navigation: "events",
  },
  {
    title: "Parents' & Student's Voice",
    navigation: "parents",
  },
  {
    title: "Infrastructure & Facilities",
    navigation: "infrastructure",
  },
  // {
  //   title: "Achievements and Laurels",
  //   navigation: "awards",
  // },
  {
    title: "News & Media Coverage",
    navigation: "news",
  },
  {
    title: "Admissions",
    navigation: "admissions",
  },
];
export const academics_sidenav = [
  {
    title: "NEP 2020 Ready School ",
    navigation: "nep",
  },
  {
    title: "Curriculum ",
    navigation: "curriculum",
  },
  {
    title: "Pedagogical Approach",
    navigation: "pedagogi",
  },
  {
    title: "Life Skill Curriculum",
    navigation: "life-skill",
  },
  {
    title: "Assessment",
    navigation: "examination",
  },
  // {
  //   title: "Skill & Vocational Subjects",
  //   navigation: "skill-vocational",
  // },
  // {
  //   title: "Results",
  //   navigation: "results",
  // },
];
export const beyond_sidenav = [
  {
    title: "Houses & Clubs",
    navigation: "house-clubs",
  },
  // {
  //   title: "Student Council",
  //   navigation: "student-council",
  // },
  {
    title: "Trips, Excursions & Field Visits",
    navigation: "trips",
  },
  // {
  //   title: "NCC, Scouts & Guides",
  //   navigation: "ncc-scouts",
  // },
];
//
export const vision_mission = [
  {
    id: 1,
    title: "Vision",
    description:
      "We at Hermann Gmeiner School believe in scholarly and accomplished learning. To provide all our students with advanced and qualified academic education along with the knowledge and experience in sports and co-curricular activities has always been our priority. Blending leisure with technology and creating a holistic experience for the educators to have the finest learning experience has been the precedence of our institution. We tend to upskill and upgrade all the children at the primary age with the will and spirit to be the best and brightest version of themselves. This helps them to grow and develop individually and in the society to become a good and contributing citizen of our country. ",
    description_image: [vision],
  },
  {
    id: 2,
    title: "Mission",
    description:
      "The mission of Hermann Gmeiner School is to create a safe space that nurtures the cerebral, social, emotional, physical, spiritual and academic development of every child. Our school strives to encourage and develop a sense of confidence and self-assurance in every juvenile. With a wide yet safe environment along with empathetic and patient staff, we want to empower our students and help them rise at every step of learning. By inculcating family and society values along with practical and theoretical knowledge, the children would learn the significance of selfhood and brotherhood.",
    description_image: [mission],
  },
];
export const message_data = [
  {
    id: 1,
    title: "President",
    name: "Rakesh Jinsi",
    image: JinsiPhoto,
    description:
      "Rakesh Jinsi, an engineer by degree, has 41 years of experience in leadership roles at several institutions, including SOS Children's Villages. Presently running Khushboo, a daycare centre for children with severe mental and multiple disabilities, he has always been active in national level bodies such  as the National Human Rights Commission and Voluntary Action Network of India (VANI), the Apex body of the NGO sector.",
    extra_description: [
      `"I firmly believe that each and every child deserves an education that is advanced, detail-oriented yet nurturing and holistic. As President of SOS Children’s Villages of India, I wish to provide the best possible learning experience to the future leaders of our country, so that all children are able to reach their maximum potential and achieve their dreams. Students end up spending most of their childhood within classrooms, so it is essential that within those walls they are imparted proper theoretical knowledge, practical aptitude, communal values, essential life skills, and an understanding of how the world works and their place in it. ,
      "Adequate grooming and learning of children at a young age leads to them being strong, confident, hard-working and intelligent individuals, attributes that I deem necessary for the new era of technology and scientific progress we are heading towards."`,
    ],
  },
  {
    id: 2,
    title: "Secretary General",
    name: "Sumanta Kar",
    image: SumantaPhoto,
    description:
      "Education at SOS Hermannn Gmeiner Schools has always meant the Holistic Development of a student – we believe that each child is entitled to the best possible skill set for their future, therefore, each child entrusted with us will receive an education that is in its finest form. ",
    extra_description: [
      "Education is preparation for life, it should be an enjoyable, interactive and ongoing process to make the students confident young adults, sensitive to global issues, committed citizens who are aware of the happenings of the world and sensible individuals with a positive attitude. ",
      "Students should have a strong character and a good understanding of their humane and emotional sides. All our Hermannn Gmeiner Schools have been actively engaged in relating to, understanding and working with communities around our schools.",
      "Safeguarding of children remains a top priority and they are nurtured through a theme-based curriculum and actively engage in experiential learning. Our teachers and principals play a pivotal role in the development of each child. Our alumni are our brand ambassadors, who leave a lasting footprint in whatever profession they choose to pursue. Each student who joins us becomes a future world leader, ready to bring progressive change and make our nation proud.",
    ],
  },
  {
    id: 3,
    title: "Head- Education & Child Development",
    name: "Debaroti Bose",
    image: DebarotiPhoto,
    description:
      "I believe that school should be the experience of a lifetime. Our school experience sets the stage for success later in life and provides a lifetime of positive memories for our students. We are proud of our continuous goal of providing an excellent education for our students.We have a dynamic, hardworking faculty who are involved in education to benefit the students.The entire gamut of school education is transforming the teaching-learning process. ",
    extra_description: [
      "In the modern concept of schooling, teaching has become redundant, paving the way for the facilitation of learning through knowledge sharing. The wave of digital learning and cloud computing is reaching new heights.",
      " In all this whirlwind of new-age technology, it is important to charge ahead but with one’s head firmly on one’s shoulders. Hermann Gmeiner School strives to inculcate moral values, manners, responsibilities among students so that they may build a world free of all the evils.",
      "Students here are encouraged to dream, groomed to give wings to their dreams, to take off and explore the unexplored. The plethora of interesting & exciting opportunities available to youngsters today often leave them with an array of things to do.",
      " It is important therefore to help them build their focus on the subject that draws them most and gives shape to their vision. A student is made to blossom intellectually, morally, and spiritually. As Swami Vivekananda said, “Take up one idea. Make that one idea your life – think of it, dream of it, and live on that idea. Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every other idea alone. This is the way to success.”",
    ],
  },
  {
    id: 4,
    title: "Principal",
    name: "Ramesh Dhar",
    image: RameshKumar,
    description:
      "The majority of people dream of being successful, yet only a few take this dream forward and work towards realizing the success they desire in their lives. For someone to be successful one needs to be ready, willing, and able to work hard for it. Is it important for a person to know the precise level of success he desires? His goals must also be smart, measurable, achievable, realistic, and time.If a person wants to succeed in life, then nothing can ever stop him from achieving it.  ",
    extra_description: [
      "He must have a strong desire and an equally strong commitment to work towards achieving his objectives, should be very optimistic, with a sharp focus on potential and not problems, and should always visualize the best possible outcome and benefits he wants to achieve and let this motivate and energize him to work towards it.",
      "Hermannn Gmeiner School has set up its tradition of keeping children motivated to channelize their energy towards something export constructive and enriching through Experiential Learning with a strong focus on achieving learning objectives. Students are given projects/ assignments that require them to learn the necessary material themselves.",
      "The key to this is the ability to get the information they need anytime anywhere without being in the physical presence of a facilitator.",
    ],
  },
];
export const infrastructure = [
  {
    id: 1,
    infra_title: "Computer Lab",
    description: `The schools have state-of –the-art computer labs to equip children with the skills necessary for this
    computer age.`,
    image: [computer],
  },
  {
    id: 1,
    infra_title: "Library",
    description: `The collection of books in the library ranges from pedagogy to philosophy. Apart from
    encyclopaedias and reference books on all subjects, the content-rich libraries have comics, story
    books and illustrated dictionaries as well. Selected weekly and monthly journals, magazines and
    newsletters, apart from daily newspaper are also subscribed. The Library has also been digitalized
    with a treasure of e-content and online material.`,
    image: [libraryinfra],
  },

  {
    id: 3,
    infra_title: "Happy Smart Classrooms",
    description: `A classroom should be a space where happiness takes precedence, where
    children are always met with a smile and with positivity. It’s a space where
    their wellbeing is as important as their academic achievements.
    Creating a support network can help to build happiness in the classroom.
    From friends, family and the adults within the school, children soon realise that
    they have important people to turn to when they need them. Feeling lonely
    and with nowhere to turn can do much to damage a child’s mental health and
    wellbeing. We create a classroom with a clear support system in place,
    children’s overall happiness and confidence can increase. 
    `,
    image: [happy],
  },
];
export const career_guidance = [
  {
    id: 1,
    title: "Career Counselling Workshop",
    description: `The future depends on what you do today." Hermannn Gmeiner School organised a Career Counseling and Guidance programme to direct the students to move on the right path. Parents from different fields and resource persons were invited to share their journey of success with students so that they could learn different aspects of success. The students engaged in the session, asked queries to have better vision for their future and were satisfied with the guidance.`,
    description_image: [career_1],
  },
  {
    id: 2,
    title: "",
    description: `Career building is a deep rooted process. It becomes tedious for adolescents to choose an appropriate career with great placements and a bright future. School plays an important role in this ever-growing process of finding oneself and establishing oneself.`,
    description_image: [career_2],
  },
  {
    id: 3,
    title: "",
    description: ` ITM Institute of Design & Media conducted a career counselling workshop on career opportunities in online world like “YOUTUBE, VFX, ANIMATION", etc. on Feb 16th ,2022. The key speaker was Ms. Jyotsna Raghunathan, who is a storyteller and a film maker with over 20 years of experience in writing scripts, directing films and working in the educational sector of media and mass communication. She has also specialized in curriculum design and development, as well as making films and writing scripts under her own company banner, Panvision. `,
    description_image: [career_4],
  },
  {
    id: 4,
    title: "",
    description: ` The workshop started with her addressing the issue of confusion being created in youngsters' mind while choosing a career. She talked about the varied courses available for all the streams and how these can be helpful in fulfilling their dreams. The students were attentive and interactive and asked queries like the exams to be taken up, combinations of subjects available, options offered, placements etc. It was an enriching session for the students of grade XII.`,
    description_image: [career_5],
  },
];
export const admission = [
  {
    id: 1,
    title: "Admission Procedure",
    image: false,
    features: [
      "Admissions are granted as and when vacancies are available in any class.",
      "Admission forms are available in the school office on 15 Jan every year from the school office. Registration forms also can be downloaded from the school website.",
      "Admission in classes prep to VIII is granted on a ‘first-come-first-served basis.",
      "For admission in class IX, the entrance exam will be of 2 hrs. in the subjects of Hindi, English, Mathematics, and General Science.",
      "For admission to the class, XI counselling will be conducted for Hermann Gmeiner School students, and for other students entrance tests will be conducted in the subjects offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms",
      "Admissions will be granted strictly on the merit of qualified candidates.",
      "25% of seats at the entry point (LKG, UKG & Class I) is reserved for EWS (Economically weaker section/ BPL cardholders) and is filled as per the list sent from the BSA office.",
    ],
  },
  {
    id: 2,
    title: "Age Criteria",
    image: false,
    features: [
      "LKG – A child completing 4 years of age as of 1st April of the Academic year.",
      "UKG - A child completing 5 years of age as of 1st April of the Academic year.",
      "Class 1 – A child must be 6 years of age as of 1st April of the Academic year.",
    ],
  },
  {
    id: 3,
    title: "Documents to be submitted at the time of Admission",
    image: false,
    features: [
      "Attest a Photocopy of Birth Certificate for LKG, UKG, and class I.",
      "Attested photocopy of Progress Report of the child indicating academic performance and participation in co-curricular activities in the school last attended.",
      "Registration with CBSE, in case, admission is sought in classes X and XII on the genuine transfer of parents in addition to (2) & (3) above and a copy of the transfer order of the parents.",
      "EWS/ BPL certificate from competent authority (if applicable).",
      "Character certificate in original from the head of the previous school.",
      "Address Proof of the parents/child",
      `Income certificate of the parents'`,
      "SC/ST/OBC Certificate from the competent authority (if applicable)",
    ],
  },
];

export const curriculum = [
  {
    title: "Primary Curriculum",
    navigation: "primary",
    text: "Designed to nurture the child in all dimensions of his or her life",
    image: primary,
  },
  // {
  //   title: "Secondary Curriculum",
  //   navigation: "secondary",
  //   text: "A skills-focused programme that provides students with a broad and balanced knowledge of key subjects",
  //   image: secondary,
  // },
];
export const pre_primary_data = [
  {
    id: 1,
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
];
export const primary_data = [
  // {
  //   id: 1,
  //   title: "Pre-Primary Curriculum",
  //   description:
  //     "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
  //   description_image: [pre1],
  // },
  // {
  //   id: 2,
  //   title: "",
  //   description:
  //     "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
  //   description_image: [pre2],
  // },
  {
    id: 1,
    title: "Primary Level",
    description:
      "Provision is made to meet the child’s needs in a manner which encourages the joy of learning. The new theme based trans-disciplinary curriculum rolled out focuses on experiential learning and makes learning fun. The students are active participants in the classroom and form sustainable relationships with the social world, nature world and with one’s self through experiences gained which stay with them throughout their life.",

    description_image: [primary_curriculum],
  },
  // {
  //   id: 2,
  //   title: "Interactive Classes ",
  //   description:
  //     "Interactive classes encourage students’ participation and engagement for better learning and overall development of a child. As the primary classes are the foundational years of education, Hermannn Gmeiner School has well-equipped Interactive board classrooms, open play areas, activity corners, reading corners, etc. to reduce dependency on textbooks and increase focus on learning by interacting. Students do a lot of activities like making floor games to learn tables and other numeracy-related topics. The children take part in poem recitations, debates, speeches, and group discussions which make them more confident and learn from their peer group.",
  //   description_image: [interactive],
  // },
  {
    id: 3,
    title: "  Happy Classrooms",
    description: `A classroom should be a space where happiness takes precedence, where children are always met with a smile and with positivity. It’s a space where their wellbeing is as important as their academic achievements.
    Creating a support network can help to build happiness in the classroom. From friends, family and the adults within the school, children soon realise that they have important people to turn to when they need them. Feeling lonely and with nowhere to turn can do much to damage a child’s mental health and wellbeing. We create a classroom with a clear support system in place, children’s overall happiness and confidence can increase.
    `,

    description_image: [happy_classroom],
  },
];
export const secondary_data = [
  {
    id: 1,
    description: `At this stage, students have the choice of a curriculum leading to the All India Secondary School Examination conducted by the Central Board of Secondary Education (CBSE), New Delhi. At Secondary school we offer:
`,
    features: [
      "Languages (English & Hindi)",
      "Science",
      "Mathematics",
      "Social Studies",
    ],
    features_image: [secondary_1],
  },
  {
    id: 2,
    title: "",
    description:
      "The secondary curriculum is aimed for the holistic development of students so that they develop effective communication and critical thinking skills. The syllabus is devised in a manner which ensures the students strengthen their work habits.The subjects chosen in this curriculum prepares the students for further studies.",
  },
];
export const sr_secondary_data = [
  {
    id: 1,
    description: `The Senior Secondary School curriculum further builds on the skills and knowledge acquired by the students in the earlier classes. Life skills like social skills, thinking skills – critical, creative, decision making and problem-solving, emotional skills, and values are embedded in the curriculum. The school offers all three streams at the Senior Secondary level- Science (Medical and Non-Medical), Commerce, and Humanities.`,
    description_image: [sr_secondary_1_1],
  },
  {
    id: 2,
    title: "",
    description: `A combination of subjects helps the students in their niche and areas they want to particularly focus on. It helps them to filter out the vast range of available opportunities. Professional guidance and counselling are very crucial at this stage. The school trains its staff according to the needs and demands of the children.
The school offers academics with a subject combination as: `,
  },
  {
    description: "srsec",
  },
];
export const alumni_data = [
  {
    id: 1,
    title: "Nikhel Bakshi",
    occupation: "Youngest Chief Flying Instructor & Trainer",
    description: `Youngest Chief Flying Instructor & Trainer
    I got the privilege of being a part of Hermannn Gmeiner School, where I not only grew physically but also emotionally and psychologically. The lessons and values taught here are embedded deep into my mind and are a part of my personality. I will cherish the experiences and memories of the school and hon'ble teachers forever. From 2006 - 2016 (10 years), I have been the Youngest Chief flying Instructor/Trainer and have trained more than 300 pilots who are now flying in different airlines in India and in foreign airlines as well. From 2017 till date, I am working as a Captain in Indigo Airlines and holding a management post as well as that of a Fleet Coordinator (Delhi Base). 
    `,
    image: nikhel,
  },
  {
    id: 2,
    title: " Ankita Lal",
    occupation:
      "Additional Senior Civil Judge in Saket District Courts, New Delhi.",
    description: `Additional Senior Civil Judge in Saket District Courts, New Delhi.
    School plays a very important part in our lives. What we are today is because of what we have learnt from our schools. I feel blessed being a part of Hermannn Gmeiner School Faridabad. The school gave me an environment which helped me excel in my academic goals. The teachers were more helpful than I had ever expected. Not only did the school cater perfectly to my academic needs but also helped me build my personality and co-curricular skills.
    `,
    image: ankita,
  },
  {
    id: 3,
    title: "Sandeep Kak",
    occupation: "General Manager - Otis Elevators",
    description: `General Manager - Otis Elevators (Bangalore, Karnataka) 
    The School is a stepping stone, responsible for the overall development of a naive and innocent child into a responsible, sensible, and law-abiding citizen. It is a home away from home for all its students and for me too, Hermann Gmeiner School was my extended family for five years. The school was responsible for shaping my character and personality. At Hermann Gmeiner School, I and most of my peers have always felt that teachers were our friends, philosophers, and guides. They cared about every student & always tried to give the best advice.
    `,
    image: sandeep,
  },
  {
    id: 4,
    title: "Bharat Bansal",
    occupation: "Entrepreneur in the USA",
    description: `Entrepreneur in the USA
    Hermannn Gmeiner School has been my alma mater for a long time. I have been extremely lucky to have studied here and have made lifelong friends in this school. FIGS has given a very unique perspective to me and my batch mates, due to its unique setting & approach to education in both curriculum and life skills. I wish the school all the best and will also be available to mentor any junior students if needed. 
    `,
    image: bharat_bansal,
  },

  {
    id: 5,
    title: "Subhadeep Sanyal",
    occupation: "Vice President - Omnivore Partners",
    description: `Vice President - Omnivore Partners 
    I earned a B.Sc. (Hons) in Agriculture from CCS HAU, Hisar where I was the batch topper (2005-09). I completed my Masters degree from IIM Ahmedabad, where I specialized in agribusiness during the PGDM- ABM (2009-11). The reason why I had chosen SOS Hermann Gmeiner School over other schools in Faridabad was due to the ethos which the school had and still continues to have. The open culture and sense of collaboration among students and faculty, complete sense of freedom for a child's overall growth and the need to be rooted to one's core values were probably the greatest takeaways for me from the school.
    `,
    image: subhadeep,
  },

  {
    id: 6,
    title: "Karan Babbar",
    occupation: "Product Consultant - Accenture Service Private Limited ",
    description: `Product Consultant - Accenture Service Private Limited 
    Excellent education is one where a child's interests are nurtured and built upon. Hermannn Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
    `,
    image: karan,
  },
  {
    id: 7,
    title: "Mr. Ajay Baisla",
    occupation: "Municipal Councilor",
    description: `Product Consultant - Accenture Service Private Limited 
    Excellent education is one where a child's interests are nurtured and built upon. Hermannn Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
    `,
    image: aa,
  },
  {
    id: 8,
    title: "Dr.Rajeev Arora",
    occupation: "Specialised Dentist and Senior lecturer.",
    description: `Specialised Dentist andSenior lecturer at Manav Rachna Dental College. Served as Hony. Secratary  of Indian Dental Association Faridabad in 2012  & 2013 and as President in 2015.
    `,
    image: a,
  },
  {
    id: 9,
    title: "Anchal Gulati",
    occupation: "DD News Anchor",
    image: b,
  },
  {
    id: 10,
    title: "Jeevant",
    occupation: "Professional Photographer",
    image: c,
  },
  {
    id: 11,
    title: "Bhushan Sachdeva",
    occupation: "Chartered Accountant",
    image: d,
  },
  {
    id: 12,
    title: "Devender Chaudhary",
    occupation: "Co convener urban local body",
    image: e,
  },
];
export const admissions_data = [
  {
    id: 1,
    title: "Admission Criteria",
    description: `Admission in the school is made without any distinction of religion, race, Caste, place of birth etc. As regards reservation for SC/ST etc. students, it is governed by the Education Act read, in accordance with the rules on the subjects as applicable in the state.
    `,
    image: false,
  },
  {
    id: 2,
    title: "",
    description: `Admission is granted as and when vacancies are available in any class.

    `,
    image: false,
  },
  {
    id: 3,
    title: "",
    description: `Admission forms are available in the school office from the 1st January depending upon the vacancy in different classes. All admission close by 31st August.Transfer cases from other states can be considered against vacancy even during the running session.
    `,
    image: false,
  },
  {
    id: 4,
    title: "",
    description: `Admission will be granted strictly on merit of qualified candidates.
    `,

    image: false,
  },
  {
    id: 5,
    title: "",
    description: `Admission to Nursery /LKG: - Student must completed 3+ years of age as on April 1, of the academic year.
    `,
    image: false,
  },
  {
    id: 6,
    title: "",
    description: `Admission to class 1: -Student must completed 5+ years of age as on April 1, of the academic year.
    `,
    image: false,
  },
  {
    id: 7,
    title: "",
    description: `Principal’s/Admission Committee’s decision will be final and binding with regard to all admission..
    `,
    image: false,
  },
  {
    id: 8,
    title: "",
    description: `Those seeking admission should produce the following:`,
    image: false,
    features: [
      "Birth certificate (for classes Nursery, UKG and I)",
      "A School Leaving Certificate from the school last attended (for classes 2 to 5).",
      `Progress Report of the child indicating Academic performance and participation in co-curricular activities in the school last attended.
      `,
      `4 Passport size photograph.`,
      `Address Proof-Aadhar Card of the child, Father and Mother.
      `,
      "Medical certificate.",
      `SC/ST/EWS/BPL/OBC Certificate if applicable.`,
      `Income Certificate of father and Mother.`,
      ` Pan Card of Father and Mother
      `,
    ],
  },
  {
    id: 10,
    title: "Mode of Payment of Fees",
    description: `Each parent will be provided with a fee Booklet containing Challan for deposit of fees at the school counter. The fee will be paid monthly within 10 days of the beginning of the month at the UCO bank counter, opposite sector 28, Mathura Road. Parents must mention the student's name, admission number, and class with a section at the back of the cheque. In case the fees are not deposited by the due date the same can be paid at the school counter in cash only.`,
    image: false,
  },
  {
    id: 11,
    title: "",
    description: `The fee without late fee is payable till the 21st of the first month at the school fee counter. In case the cheque is dishonored, the payment of Rs 200/- shall be charged extra from the parents. The due fee will have to be paid as long as the pupil’s name is on the rolls.`,
    image: false,
  },
  {
    id: 12,
    title: "",
    description: `Only those pupils who have paid all school dues are permitted to appear for the promotion examination. Parents must clear all fees dues by 31st January to avoid any inconvenience. Admission and Annual Fees will not be refunded if a pupil is withdrawn from the school. Security deposit will be refunded only on production of the receipt copy of the Depositor’s portion of the Fee Challan.`,
    image: false,
  },
  {
    id: 13,
    title: "",
    description: `Students' admission numbers should be written in legible handwriting at the time of depositing the fees. Fees lost on account of the wrong entry of name or admission number shall be the responsibility of the parent and in such a case the student's name shall fall in the defaulter list.`,
    image: false,
  },
];
export const facilities_data = [
  {
    id: 1,
    title: "Spacious Classrooms",
    description: `The School can boast spacious, well-ventilated, and excellently furnished classrooms to make the students learning comfortable and enjoyable. Individual tables and chairs are provided to students to make them independent and self-reliant. These specially designed tables and chairs can be brought together very easily to form circles and semicircles while the lesson is in progress to have group discussions and activities.`,
    image: [spacious_classroom],
  },
  {
    id: 2,
    title: "",
    description: `To avoid carrying heavy school bags,  a special storage facility is provided for every student in the classroom. In all classrooms, display boards are provided to display creative chart works, drawings and paintings, and craftworks. The school has the best infrastructure catering for today’s learning environment – like smart interactive classrooms with ICR facilities and the use of smart board technologies for conducive learning. This facilitates comprehensive learning and makes it enjoyable and an interesting process of learning.
    `,
    image: [spacious_classroom_2],
  },
  {
    id: 3,
    title: "STEM Lab",
    description: `STEM education experiences are made available in a variety of settings by the school as a way of fostering a diverse STEM workforce. Educators are focused on improving science and mathematics instructions employing several approaches to K-12 STEM education. Students also were given opportunities to spend time with professionals in STEM fields. 
    `,
    image: [stem_lab],
  },
  {
    id: 4,
    title: "",
    description: `STEM is an abbreviation that stands for 4 closely related subjects that are Science, Technology, Engineering and Mathematics. These four theoretical and practical subjects are taught together under STEM education and starting from basics to giving precise knowledge of the subjects, professional guidelines are available at the school’s STEM lab. The students are taught by giving them real-life world problems and they are to solve these problems by theoretical and practical approach. 
    `,
    image: [stem_lab_2],
  },
  {
    id: 5,
    title: "Computer Lab",
    description: `The school has a highly sophisticated state-of-the-art computer lab. It is equipped with a computing facility with i3 computers connected through the Local Area Network as well as Wifi. The high-speed internet connectivity of 11 Mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum.
    `,
    image: [computer_lab_1],
  },
  {
    id: 6,
    title: "",
    description: `Students are expected to use computers in their day-to-day study to meet the challenges of modern learning and to keep pace with global scenarios. Students are provided training on SAP, Tally ERP 9, MS Office-2016, and the Internet. The Institute website has been dynamically designed to update the latest development in the institute on a day to day basis.
    `,
    image: [computer_lab_2],
  },
  {
    id: 7,
    title: "Laboratories",
    description: `A scientific temperament is nurtured through fully equipped and suitably designed laboratories for Physics, Chemistry, and Biology. Students are encouraged to use the facilities for enhancing their innovative and scientific skills. It also provides an open environment for students to experiment and do research work. All these labs are provided with lecture/ seminar areas too. The three divisions of the science department ensure that students do real experiments.
    `,
    image: [laboratories],
  },
  {
    id: 8,
    title: "",
    description: `Our science labs are not just a rich storehouse of costly equipment and the latest gadgets on display. They are workshops of practical experiments going far beyond the limits of the prescribed syllabus. The teachers at the physics, chemistry, and biology labs challenge them with exciting projects, giving them a strenuous time clashing with known principles and existing data. This is a place where the divine curiosity of students is at work.
    `,
    image: [laboratories_2],
  },
  {
    id: 9,
    title: "Home Science Laboratory",
    description: `Home Science is a very important subject in gaining knowledge to meet the challenge of day-to-day life with confidence. The school has a well-equipped Home-Science Lab where students are acquainted with hands-on knowledge and skills. The teacher conducts different activities in the lab like cooking, stitching, embroidery, knitting, and home management. The home science students can learn Diet Planning for regular and therapeutic needs, Floor decoration, Tie & Die, Flower Arrangement, Block Printing, Samples of different Stitches, Cooking of different recipes, and Table setting.
    `,
    image: [home_science],
  },
  {
    id: 10,
    title: "",
    description: `The home science teacher demonstrates various recipes on bakery, salads, Indian, Chinese, Mexican, and Italian cuisines. The students learn the art of cooking and hone their culinary skills by using gadgets and equipment. They also make use of these lab facilities in their practice teaching, final skill-in teaching, and craft exam.
    `,
    image: [home_science_2],
  },
  {
    id: 11,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The Art Room fosters the creative and intellectual development of the students through quality visual arts programs, facilitated by the arts faculty. The art room drives each child to be curious and original in their work while they study a variety of Art forms such as 2D, 3D, and installations by exploring mediums like acrylic, charcoal, water, dry pastels, oil pastels, pencil coloring, clay, wires, papier mache, etc.
    `,
    image: [arts],
  },
  {
    id: 12,
    title: "",
    description: `The periods in the Art Room offer each child a learning experience that teaches him or her to be a risk-taker, thinker, inquirer, and a good communicator in a safe environment. The Art teachers help the students in drawing, painting, crafts work, best out of waste, and clay modeling. Providing the students with experiences in art, craft and design enables them to reflect critically on their own and others’ work. We aim that they learn to think and act as artists, working creatively and intelligently.
    `,
    image: [arts_2],
  },
  {
    id: 13,
    title: "Library",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites young users to delve into the magical world of words and the wealth of research material available. The school has a well-equipped library.  The eclectic collection that export constitutes the Library currently includes over 10,000 books. The Library resources comprise Encyclopedias, Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well as specific skills-related books on Personality Development, Time Management, and Communication Skills. 

    `,
    image: [library],
  },
  {
    id: 14,
    title: "",
    description: `The Library subscribes to research e-journals, besides subscribing to daily newspapers, magazines, and journals. The Digital library provides access to information not just to widen the horizons of the users' knowledge but also to support a host of their learning activities that range from book learning and newspaper reading to using e-journals and reading e-books.
    `,
    image: [library_2],
  },
  {
    id: 15,
    title: "Cultural Center (Music & Dance Room)",
    description: `This is the place where young musicians start their journey to become great composers & where the children unwind and learn the aesthetics of movement. Classical Dance (Indian & Western), Modern Dance (Indian & Western), Choreography,  Direction,  Vocal  Music (Indian and Western), Instrumental Music (Indian and Western) are all interwoven into the fabric of our educational experience.`,
    image: [cultural],
  },
  {
    id: 16,
    title: "",
    description: `Dance is an ideal means of communication to express what is too deep, too fine for words. Hermannn Gmeiner School’s students learn to choreograph and are conversant with various Indian and Western dance forms like Indian Classical, Jazz, Hip Hop, Salsa and these are some of the most eagerly awaited moments for self-expression and the students can be seen enjoying to the hilt.`,
    image: [cultural_2],
  },
  {
    id: 17,
    title: "",
    description: `Lessons in Indian and Western music generate rich cross-cultural exposure and sensitize the students to this fine art. The Department of Music and Dance is committed to excellence, innovation, learning in action, and entrepreneurship. Our mission is to bridge dance practice and scholarship and to foster interdisciplinary inquiry.`,
    image: [cultural_3],
  },
  {
    id: 18,
    title: "",
    description: `As a trained faculty, we bring a challenging range of perspectives to our teaching and research, and continually re-examine our curriculum to prepare both young and mature aspirants for careers in the ever-evolving field of Performing Arts.`,
    image: [cultural_4],
  },
];

export const results_data = [
  {
    title: "Toppers: X",
    description_image: [result_10, result_10_1, result_10_2, result_10_3],
    custom_class: "custom-img",
  },
  {
    title: "Toppers: XII",
    description_image: [result_12_1, result_12_2, result_12_3],
    custom_class: "custom-img",
  },
];

export const about_data = [
  {
    title: "About Us",
    description:
      "Hermannn Gmeiner School, Faridabad was founded in 1986 by Shri J.N Kaul who was the then President of SOS Children’s Village of India. It is one of the oldest schools in Faridabad with a bunch of exceptionally talented students and alumni. It is a co-educational school with classes from Nursery to XII (Medical, Non-Medical, Commerce, and Humanities).",
  },
  {
    title: "",
    description:
      "The school has world class infrastructure that makes it comfortable for the students to learn and grow. The faculty leaves no stone unturned to look after the development of the students. The alumini’s experiences speak for the successful careers the students established here.",
  },
  {
    title: "Our Values",
    description:
      "Hermannn Gmeiner was a man of principles and ethics. His love and compassion for children is what led him to do the social deed of education and establish SOS Children’s Village Association in 1949. Today, these institutions are active in almost 135 countries, spreading its love and knowledge.",
  },
  {
    title: "",
    description:
      "Our value and virtue is what we strive for and work our very best for all our students to learn and adapt the goodness all over. We teach our children how to excel in not only academics but every field out there and how to make a name and position for self. We teach them how to handle life’s hardships along with book and physical knowledge.",
  },
];
export const nep_data = [
  {
    id: 1,
    icon: <FaSchool />,
    description:
      "Hermannn Gmeiner School pedagogy strives to make the schooling process more insightful. It has expanded the horizon of learning and made it more interesting, and motivating. The aim is to focus on the all-round development of the students rather than focusing only on the theoretical part. This makes them ready to face the outer world.",
    extra_description: ["NEP"],
  },
  {
    id: 2,
    title: "Our Curriculum aims to",
    description: "",
    features: [
      "Encourage students to think critically and solve problems through project-based learning",
      "Seamlessly integrate art into the curriculum to enhance the appreciation for aesthetic visual and performing arts.",
      "Foster holistic development through sports, i.e. Health and Physical Education.",
      "Offer activities for curricular and co-curricular enrichment via clubs and work education.",
      " Engage students in addressing real-world problems and issues important to humanity to lead change.",
      " Provide differentiated teaching-learning activities to personalize learning.",
    ],
  },
  {
    id: 3,
    title: "Key Features of the Curriculum",
    description: "",
    features: [
      "Individualized attention – low student-teacher ratio",
      "Stimulating learning environment",
      "Emphasis on all-round development and co-curricular activities",
      "Integration of ICT and Innovation in the Curriculum",
      "Parental Engagement Programmes",
      " Eco-Literacy Curriculum",
      "360-degree assessment approach",
      "Skill-based subjects Imbibing values & social skills",
    ],
  },
];
export const life_curriculum_data = [
  {
    id: 1,
    description: `Adapting the Life Skill Curriculum in the daily routine of every child at the primary stage is a very crucial part of our education. The basic hygiene importance like handwashing and daily bathing, and the value of chivalry and gratitude towards one another must be taught at their impressionable age and fortified over the years. The three components of the Life Skill Curriculum; the individual life-skill, the social skill, and the effective decision making skills are some of the eminent topics of our syllabus. To not only make our students academic geniuses, but to also refine their social and personal skills is what we aim for.`,
    description_image: [life_skill_1],
  },
  {
    id: 2,
    title: "",
    description: `We believe in testing and creating challenges for our students, so they can break their barriers and overcome their judgemental and stereotypical fears. To educate all of them about daily chores, irrespective of the gender, makes the batchmates comfortable and more open to these topics. The ten core Life Skills adopted by our education system are - 1. Self-awareness, 2. Empathy, 3. Critical Thinking, 4. Creative Thinking, 5. Decision Making, 6. Problem Solving, 7. Effective Communication, 8. Interpersonal relationship, 9. Coping with stress, 10. Coping with Emotion.
    `,
    description_image: [life_skill_2],
  },
];
export const examination_data = [
  {
    id: 1,
    title: "Assesment",
    description: `At specific periods of time, we conduct secure and stable examinations for the students of all grades to not only test their capabilities but also to help them gain self-confidence. The children are marked on the basis of their academic and co-curricular results along with their year-long behaviour and activities.`,
  },
];
export const skill_vocational_data = [
  {
    description: [
      `Vocational subjects include more hands-on training and less of the traditional academic learning. We at Hermann Gmeiner School focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.`,
      `We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.`,
      `Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths.`,
    ],
  },
  {
    title: "Classes VI-VIII",
    features: ["Mass Media", "Financial Literacy", "Handicraft"],
  },
  {
    title: "Classes  IX-X",
    features: [
      "Information Technology",
      "Banking & Insurance",
      "Introduction to Tourism",
    ],
  },
  {
    title: "Classes XI-XII",
    features: ["Web Application", "Financial Literacy", "Mass Media "],
  },
];
export const co_curricular_data = [];
export const house_clubs_data = [
  {
    id: 1,
    title: "Health Club",
    description: `A Healthy Mind Resides in a Healthy Body defines Physical Education as ‘Powerful Education’, and consider exercise and recreation as necessary as reading and writing. We strongly believe that intelligence and skill can only function at the peak of their capacity when the body is healthy and strong. Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity.
    Students have to participate in school sports to increase confidence, mental alertness, and self-esteem. It helps to teach various skills to students like leadership, patience, team efforts, and social skills.
    School organizes various activities like different types of races, Kho- Kho and cricket matches, long jump and high jump.
    `,
    image: [sports_club],
    icon: [health_club_icon],
  },
  {
    id: 2,
    title: "Literary Club",
    description: `The aim of the club is to help the students acquire better communication skills, develop interest in the language, its listening, speaking and writing perceptions. The student’s exhibit shows conviction and confidence through various activities like Poem Recitation competition, Show and tell, word building, quiz competition and declamation competition etc.
    Through Literary Club inculcate a flair for the language and enhance their literary skills. To induce reading habits in students, encourage students to become orators and display their intellectual, independent thinking skills and a sense of confidence.`,
    image: [anti_bullying],
    icon: [anti_bullying_icon],
  },
  {
    id: 3,
    title: "Environment Club",
    description: `Environment club is the heart of school’s sustainability activities and thus helps to achieve meaningful change in the society. The Club inspires, motivates and gives students opportunities to build leadership skills. The Club gives students the platform they need to take action on the environmental issues they feel passionate about.
    The Club is dedicated to environmental awareness activities related to protection, conservation, preservation, and restoration of Natural resources. It campaigns for the improvement of the environment through various activities. Emphasis is on educating peers about harmful effects of Polybags, Holi colors, polluting agents and firecrackers etc. 
    ’.
    `,
    image: [environment_club],
    icon: [environment_club_icon],
  },

  {
    id: 7,
    title: "Social Impact Club",
    description: `The purpose of the club is to tie together formal and informal learning and give back to the community. Student participation can contribute to the development of personal responsibility, moral values, cooperation, emotional maturity, self-discipline, social competence, individuality, leadership, a realization of the value of group goals, and an understanding of democratic processes.
    Throughout the year, the social Impact club performed various activities to help the environment, as well as other people in general. Students enthusiastically participate in all activities for animal welfare, underprivileged children, adult education, awareness programmes etc.

    ’
    `,
    image: [social_impact],
    icon: [social_impact_club_icon],
  },

  {
    id: 10,
    title: "Expressions Club",
    description: ` As we aim at the holistic development of our young students. The school rears a platform for the young saplings where they get manifold opportunities to boost up their expression and  strengthen creative process by consistently motivating them to acquire the requisite skills by actively participating in the following Performing Arts - theater,  Music, Dance, Art and Craft. 
    The enthusiastic participation of each child in these performing arts enhances their ability to imagine, to  create, to perform, and to design. For we believe that, thinking  creatively, giving shape to ideas, and expressing those ideas through different genres  is  not only indispensable to all artistic endeavors, but it also facilitates and enriches the conventional academic pursuits, stimulates problem-solving ability, and  foster originality in concepts among enthusiastic learners  in their tender years .
    
    `,
    image: [expression_club],
    icon: [expression],
  },

  // {
  //   id: 13,
  //   title: "School Disaster Management Committee (SDMC)",
  //   description: `The school disaster management committee makes students aware of the various hazards and disasters. It conducts online and offline sessions where the students are shown the videos like fire safety, earthquake readiness, etc. It conducts mock drills in school so that the children get hands-on experience of dealing with such situations.
  //   `,
  //   image: [sdmc],
  // },
  // {
  //   id: 14,
  //   title: "",
  //   description: ` It also includes making students empathetic towards their classmates, making them understand the varying needs of their fellow students and people. It imparts understanding on concepts like the buddy system, children with special needs (orthopedic, visual, or learning). With this, children become compassionate and helpful towards their classmates and people in need.`,
  //   image: [sdmc_2],
  // },
];

export const houses_school = [
  {
    id: 1,
    title: "Jagriti House ",
    description: `Jagriti means awakening or consciousness-raising and the house carries the color blue. Blue is symbolic of both the sky and the sea. It creates a connection between people and their surroundings. It is associated with freedom, intuition, imagination, and empathy. It represents intelligence, sincerity, confidence, and strength and is instrumental in the betterment of the mind and the body. Jagriti House represents an inner journey symbolizing wisdom and depth of understanding.
    `,
    color: "#0440B6D6",
    houseImg: [blue],
  },
  {
    id: 18,
    title: "Smriti House ",
    description: `Smriti refers to the collection of acara or customary law wherein learning takes place. The house carries the color Yellow. It is the color that represents joy. It symbolizes optimism, enlightenment, and creativity. It fosters positive and logical ways of thinking. The house believes in creating enthusiastic learners and positive thinkers for society
    `,
    color: "#FFF616",
    houseImg: [yellow],
  },
  {
    id: 19,
    title: "Jyoti House ",
    description: `Jyoti means light, the brightness that lets us see and the color is Red. Red is the color that symbolizes fire and blood. This signifies passion, desire, strength, willpower, and determination. It makes students confident and passionate learners.
    `,
    color: "#FF1212",
    houseImg: [red],
  },
  {
    id: 20,
    title: "Pragati House ",
    description: `Pragati house carries the color green and the name means the advancement of something which is the process of helping it to progress. The green house carries the color of life, renewal, and energy. It promotes growth and progress. It is the symbol of Mother Nature and the environment that surrounds it. The importance of instilling sensitivity towards the environmental challenges is an important belief of the house.
    `,
    color: "#24EA03F5",
    houseImg: [green],
  },
];
export const student_council_data = [
  {
    id: 1,
    description: `The school comprises the student council headed by the Head Girl and Head Boy along with the Vice Head Girl and Vice Head Boy. The houses are represented by the House Captains and Vice Captains. The council also has representation for the cultural, sports, and discipline committees represented by students heading these particular areas. 
    `,
    description_image: [student_council_1],
  },
  {
    id: 2,
    title: "",
    description: `The students who are elected as heads of all the various committees and councils are guided and trained for undertaking their responsibility with sincerity and passion by the teachers and house in charge. The responsibility of discipline of other students and maintenance of peace and dignity in school also falls under the duties of these elected leaders. The Student Council not only looks after the school's regulations but also helps the students in various activities and campaigns.`,
    description_image: [student_council_3],
  },
];

export const grading_Data = [
  {
    id: 1,
    title: "GRADE",
    name: "PERFORMANCE",
    description: "% Range",
  },
  {
    id: 2,
    title: "A+",
    name: "Outstanding",
    description: "90-100",
  },
  {
    id: 3,
    title: "A",
    name: "Excellent",
    description: "75 – 89.99",
  },
  {
    id: 4,
    title: "B",
    name: "Very Good",
    description: "56 – 74.99",
  },
  {
    id: 5,
    title: "C",
    name: "Good",
    description: "35 – 55.99",
  },
  {
    id: 4,
    title: "D",
    name: "Scope For Improvement",
    description: "0 – 34.99",
  },
];

export const assesment_Data = [
  {
    id: 1,
    title: "Classes I & V",
    name: "Assessment Dates",
  },
  {
    id: 2,
    title: "FA 1",
    name: "FROM JULY 26th , 2022",
  },
  {
    id: 3,
    title: "FA 2",
    name: "FROM SEPTEMBER 19th , 2022",
  },
  {
    id: 4,
    title: "FA 3",
    name: "FROM NOVEMBER 22nd , 2022",
  },
  {
    id: 5,
    title: "FA 4",
    name: "FROM MARCH 6th , 2022",
  },
];
export const disclosure_data_general = [
  {
    id: 1,
    title: "S.No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "NAME OF THE SCHOOL",
    description: "Hermannn Gmeiner School",
  },
  // {
  //   id: 3,
  //   title: "2",
  //   name: "AFFILIATION NO (IF APPLICABLE)",
  //   description: "530080",
  // },
  // {
  //   id: 4,
  //   title: "3",
  //   name: "SCHOOL CODE (IF APPLICABLE)",
  //   description: "40078",
  // },
  {
    id: 5,
    title: "4",
    name: "COMPLETE ADDRESS WITH PIN CODE",
    description: `VILLAGE ANANGPUR, FARIDABAD –

    121003, HARYANA`,
  },
  {
    id: 6,
    title: "5",
    name: "PRINCIPAL NAME",
    description: "Ms. Vandana Tomar",
  },
  {
    id: 7,
    title: "6",
    name: "PRINCIPAL QUALIFICATION",
    description: "",
  },
  {
    id: 8,
    title: "7",
    name: "SCHOOL EMAIL ID",
    description: `principal.anangpur@hermann-gmeiner-edu.org`,
  },
  {
    id: 9,
    title: "8",
    name: "CONTACT DETAILS (LANDLINE/MOBILE)",
    description: `+91 9899786561`,
  },
];
export const disclosure_data_documents = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE",
  },
  {
    id: 2,
    title: "1",
    name: `COPIES OF SOCIETIES/TRUST/COMPANY
    REGISTRATION/RENEWAL CERTIFICATE, AS
    APPLICABLE`,
    description: "",
    customcomponent: require("../src/pdf/society.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: `COPY OF VALID FIRE SAFETY CERTIFICATE
    ISSUED BY THE COMPETENT AUTHORITY`,
    description: "",
    customcomponent: require("../src/pdf/fire.pdf"),
  },
  // {
  //   id: 4,
  //   title: "3",
  //   name: "COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT",
  //   description: require("../src/pdf/noc.pdf"),

  // },
  // {
  //   id: 5,
  //   title: "4",
  //   name: "COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE",
  //   description: require("../src/pdf/society.pdf"),
  // },
  // {
  //   id: 6,
  //   title: "5",
  //   name: "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE",
  //   description: "",
  //   customcomponent: require("../src/pdf/building.pdf")
  // },
  // {
  //   id: 7,
  //   title: "6",
  //   name: "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY",
  //   description: "",
  //   customcomponent: require("../src/pdf/fire.pdf")

  // },
  // {
  //   id: 8,
  //   title: "7",
  //   name: "COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/ UPGRADATION/ EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL",
  //   description: "",
  //   customcomponent: require("../src/pdf/affilation.pdf")

  // },
  // {
  //   id: 9,
  //   title: "8",
  //   name: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES",
  //   description: "",
  //   customcomponent: require("../src/pdf/HYGIENE.pdf")

  // },
];
export const disclosure_data_results = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE",
  },
  {
    id: 2,
    title: "1",
    name: "FEE STRUCTURE OF THE SCHOOL",
    customcomponent: require("../src/pdf/fee.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "ANNUAL ACADEMIC CALENDAR",
    description: "",
    customcomponent: require("../src/pdf/anual_calender.pdf"),
  },
  // {
  //   id: 4,
  //   title: "3",
  //   name: "LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)",
  //   description: require("../src/pdf/member.pdf"),

  // },
  // {
  //   id: 5,
  //   title: "4",
  //   name: "LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS",
  //   description: require("../src/pdf/PTA.pdf"),

  // },
  // {
  //   id: 6,
  //   title: "5",
  //   name: "LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY",
  //   description: "",

  // },
];
export const teaching_Staff = [
  {
    id: 1,
    title: "S.NO",
    name: "Name",
    designation: "Designation",
    qualification: "Qualification",
  },
  // {
  //   id: 2,
  //   title: "1",
  //   name: "Mr.Ramesh Dhar",
  //   designation: "Principal",
  //   qualification: "MA, B.ED",
  // },
  {
    id: 3,
    title: "1",
    name: "Vandana Tomar",
    designation: `Head Mistress`,
    qualification: "M.com, PG Journalism & Mass Communication, B.ED",
  },
  {
    id: 4,
    title: "2",
    name: "Ms.Monika Bajaj",
    designation: "PRT",
    qualification: "MA English, B.ED(Social Science & English)",
  },
  {
    id: 5,
    title: "3",
    name: "Ms.Ushavati Bhadana",
    designation: "PRT",
    qualification: "BA, B.ED",
  },
  {
    id: 6,
    title: "4",
    name: "Mrs. Susha Sopory",
    designation: "TGT",
    qualification: "MA Sanskrit, B.ED, CTET",
  },
  {
    id: 7,
    title: "5",
    name: "Mrs.Anju Singh",
    designation: "PRT",
    qualification: "12th ,NTT",
  },
  {
    id: 8,
    title: "6",
    name: "Mrs. Manju Aneja",
    designation: "PRT",
    qualification: "12th,NTT",
  },
  // {
  //   id: 9,
  //   title: "7",
  //   name: "Ms. Jaya Bhatia",
  //   designation: "Teacher",
  //   qualification: " B.Com, MBA(Finance), B.ED, CTET Qualified",
  // },
  {
    id: 10,
    title: "7",
    name: "Mrs.Krishna Saini",
    designation: "PRT",
    qualification: "12TH NTT",
  },
  {
    id: 11,
    title: "8",
    name: "Ms. Deepali Tanwar",
    designation: "PRT",
    qualification: "B. Ed, B.Com, CTET",
  },
  {
    id: 12,
    title: "9",
    name: "Ms. Anjali Bhadana",
    designation: "PRT",
    qualification: "B. Ed, M.Com, CTET",
  },
];

export const co_activity = [
  {
    id: 1,
    title: "S.NO",
    name: "Name",
    designation: "Designation",
    qualification: "Qualification",
  },
  {
    id: 2,
    title: "1",
    name: "Mr. GL Pandita",
    designation: "PET",
    qualification: "B.A., B.PED",
  },
  {
    id: 3,
    title: "2",
    name: "Mrs. Mangla Chavan",
    designation: "PRT",
    qualification: "12th & D. Ed",
  },
];
export const admin_staff = [
  {
    id: 1,
    title: "S.NO",
    name: "Name",
    designation: "Designation",
    qualification: "Qualification",
  },
  {
    id: 2,
    title: "1",
    name: "Mr. Vikram Chawla",
    designation: "Administrator",
    qualification: "LLB, BCA, GNIIT",
  },
  // {
  //   id: 3,
  //   title: "2",
  //   name: "Mr. Anil Kaul",
  //   designation: "Office Assistant",
  //   qualification: "BA",
  // },
];
export const disclosure_data_staff = [
  {
    id: 1,
    title: "S.NO",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "PRINCIPAL",
    description: "Ms. Vandana Tomar",
  },
  {
    id: 3,
    title: "2",
    name: "TOTAL NUMBER OF TEACHERS",
    name1: "PRT",
    name2: "NTT",
    name3: "D.ED",
    name4: "PET",
    description: "9",
    description1: "4",
    description2: "3",
    description3: "1",
    description4: "1",
  },
  {
    id: 4,
    title: "3",
    name: "TEACHERS SECTION RATIO",
    description: "1.30",
  },
  {
    id: 5,
    title: "4",
    name: "DETAILS OF SPECIAL EDUCATOR",
    description: "5",
  },
  {
    id: 6,
    title: "5",
    name: "DETAILS OF COUNSELLOR AND WELLNESS TEACHER",
    description: "NISHTHA JATWANI MANOCHA",
  },
];
// export const disclosure_data_results_10 = [
//   {
//     id: 1,
//     title: "S. No",
//     extra_title: "YEAR",
//     name: "NO. OF REGISTERED STUDENTS",
//     description: "NO. OF STUDENTS PASSED",
//     extra_description: "PASS PERCENTAGE",
//   },
//   {
//     id: 2,
//     title: "1",
//     extra_title: "2019",
//     name: "82",
//     description: "82",
//     extra_description: "100%",
//   },
//   {
//     id: 3,
//     title: "2",
//     extra_title: "2020",
//     name: "123",
//     description: "123",
//     extra_description: "100%",
//   },
//   {
//     id: 4,
//     title: "3",
//     extra_title: "2021",
//     name: "128",
//     description: "128",
//     extra_description: "100%",
//   },
//   {
//     id: 5,
//     title: "4",
//     extra_title: "2022",
//     name: "99",
//     description: "99",
//     extra_description: "100%",
//   },
// ];
// export const disclosure_data_results_12 = [
//   {
//     id: 1,
//     title: "S. No",
//     extra_title: "YEAR",
//     name: "NO. OF REGISTERED STUDENTS",
//     description: "NO. OF STUDENTS PASSED",
//     extra_description: "PASS PERCENTAGE",
//   },
//   {
//     id: 2,
//     title: "1",
//     extra_title: "2021",
//     name: "95",
//     description: "95",
//     extra_description: "100%",
//   },
// ];
export const disclosure_data_school = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)",
    description: "772",
  },
  {
    id: 3,
    title: "2",
    name: "NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)",
    description: "8, 5.77 * 6.27",
  },
  {
    id: 4,
    title: "3",
    name: "NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQUARE MTR)",
    description: "",
    description1: "1, 5.77 * 6.27",
  },
  {
    id: 5,
    title: "4",
    name: "INTERNET FACILITY",
    description: "YES",
  },
  {
    id: 6,
    title: "5",
    name: "NO. OF GIRLS TOILETS",
    description: "3",
  },
  {
    id: 7,
    title: "6",
    name: "NO. OF BOYS TOILETS",
    description: "3",
  },
];
export const trip_data = [
  {
    id: 1,
    title: `“The use of travelling is to regulate imagination with reality, and instead
    of thinking of how things may be, see them as they are.”`,
    description: `Learning though recreation  is self-learning. We organize trips and day long picnics and
    excursions for the students’ enrichment. Picnics and excursions are days full of fun and
    frolic, The School excursion is an important and effective means of motivating students and
    engaging them in active learning experiences. An excursion is not just a pleasure trip; it is an
    extension of the classroom. It is a direct source of knowledge for students and acquaints
    them with first-hand information. It helps in the development of power of observation,
    
    exploration, and inculcation of values like tolerance. Inter-personal relationships and
    empathy for others.`,
    description_image: [trip_cover],
  },
];
export const ncc_scouts_guides_data = [
  {
    id: 1,
    description: `To inculcate discipline and leadership qualities, a large number of students are being trained under the NCC & Scout and Guides for making them capable, sensitized to work for the betterment of society and for enhancing their lifestyle and leadership qualities.`,
  },
  {
    id: 2,
    title: "NCC",
    description: `To establish the quality of leadership, to enhance their grace and to give all the students the pride and confidence, we give all the interested candidates special NCC training. Tough yet specialised physical and mental training along with fun sessions help the participants to develop and relax simultaneously. Specially trained teachers and officers often take various lectures and seminars to help and guide the students.`,
    description_image: [ncc_1],
  },
  {
    id: 3,
    title: "School band",
    description: `Music has always proven to be one of the best therapies and to train the students in all aspects, our school has a special band. Training and understanding of various musical instruments, importance of teamwork and knowledge of musical notes are provided to the students. The school band often performs in different events and functions of the schools for which the equipment is both provided as well as allowed to bring on their own.
    `,
    description_image: [school_band_1],
  },
  {
    id: 4,
    title: "Scouts and Guides",
    description: `All eligible and interested students are given fun but important teachings of scouts and guides. It helps them grow physically, mentally, socially and intellectually. Uniforms and other requirements that we provide are a must to bring in the quality of discipline among them. All of them are expected to learn the rules and regulations by heart and implement the same in their actual lives, hence making them stronger and more confident.`,
    description_image: [scouts_1],
  },
];
export const transportation_data = [
  {
    title: "",
    description: `The school provides an optional facility for students to commute from home to school. This service is offered from various places of Faridabad and Delhi to the school. The buses are operated by experienced & trained drivers/attendants to monitor the children’s safe journey. The school tries to provide safe and comfortable commuting for students from home to school and back. Our buses are specially equipped and fitted with speed governors, rear cameras, first-aid equipment, fire extinguishers, etc.`,
    description_image: [bus_1],
    custom_class: "custom-img",
  },
];
export const award_text_data = [
  {
    title: "",
    description: [
      "Hermann Gmeiner School proudly highlights its achievements and presents the best of its students, whether they are excelling academically, athletically or in service to the community. We hope to inspire more students to excel, their achievements ultimately make the school become stronger.",
      "By highlighting student achievements, we aim to eliminate self-doubt and build confidence in our students that hard work is important and is making an impact. In turn, this confidence can carry over to other facets of their young lives, including making smart choices when faced with several challenges as they grow up.",
    ],
  },

  {
    features: [
      "Global School Award for Innovation in Pedagogical Practices in 2020",
      "Best Teaching Excellence Award by International School Award held at Dubai in 2018",
      "Indian School Award in 2018",
      "National School Award 2018",
      "Global Sustainable School Award 2017 as a recognition of its efforts towards community service and environment",
    ],
  },
];
// the long list of awards such as
//stand testimony to the fact that the school never rests on its laurels.

export const awards_data = [
  {
    title: "",
    image: award_8,
  },
  {
    title: "",
    image: award_10,
  },
  {
    title: "",
    image: award_9,
  },
  {
    title: "",
    image: award_1,
  },
  {
    title: "",
    image: award_2,
  },
  {
    title: "",
    image: award_3,
  },
  {
    title: "",
    image: award_4,
  },
  {
    title: "",
    image: award_5,
  },
  {
    title: "",
    image: award_6,
  },
  {
    title: "",
    image: award_7,
  },
];
export const news_text_data = [
  {
    description:
      "Hermannn Gmeiner School has been incessantly creating fervor in society and has been garnering Media coverage, getting its message across to a wider audience and establishing itself as a credible school and its activities as notable.Reputed newspapers have on several ocaasions, published the events and happenings at Hermann Gmeiner School along with the laurels and accolades received. We humbly acknowledge this recognition and strive to be a responsible school and be dedicated towards the community welfare.",
  },
];
export const news_data = [
  // {
  //   title: "",
  //   image: media1,
  // },
  {
    title: "",
    image: media2,
  },
  // {
  //   title: "",
  //   image: media3,
  // },
  {
    title: "",
    image: media4,
  },
  // {
  //   title: "",
  //   image: media,
  // },
  // {
  //   title: "",
  //   image: media6,
  // },
  {
    title: "",
    image: media8,
  },
  {
    title: "",
    image: media9,
  },
  {
    title: "",
    image: media10,
  },
];
export const alumni_text_data = [
  {
    id: 1,
    title: "Nikhel Bakshi",
    description: `Youngest Chief Flying Instructor & Trainer
    I got the privilege of being a part of Hermannn Gmeiner School, where I not only grew physically but also emotionally and psychologically. The lessons and values taught here are embedded deep into my mind and are a part of my personality. I will cherish the experiences and memories of the school and hon'ble teachers forever. From 2006 - 2016 (10 years), I have been the Youngest Chief flying Instructor/Trainer and have trained more than 300 pilots who are now flying in different airlines in India and in foreign airlines as well. From 2017 till date, I am working as a Captain in Indigo Airlines and holding a management post as well as that of a Fleet Coordinator (Delhi Base). 
    `,
    image: nikhel,
  },
  {
    id: 2,
    title: " Ankita Lal",
    description: `Additional Senior Civil Judge in Saket District Courts, New Delhi.
    School plays a very important part in our lives. What we are today is because of what we have learnt from our schools. I feel blessed being a part of Hermannn Gmeiner School Faridabad. The school gave me an environment which helped me excel in my academic goals. The teachers were more helpful than I had ever expected. Not only did the school cater perfectly to my academic needs but also helped me build my personality and co-curricular skills.
    `,
    image: ankita,
  },
  {
    id: 3,
    title: "Sandeep Kak",
    description: `General Manager - Otis Elevators (Bangalore, Karnataka) 
    The School is a stepping stone, responsible for the overall development of a naive and innocent child into a responsible, sensible, and law-abiding citizen. It is a home away from home for all its students and for me too, Hermann Gmeiner School was my extended family for five years. The school was responsible for shaping my character and personality. At Hermann Gmeiner School, I and most of my peers have always felt that teachers were our friends, philosophers, and guides. They cared about every student & always tried to give the best advice.
    `,
    image: sandeep,
  },
  {
    id: 4,
    title: "Bharat Bansal",
    description: `Entrepreneur in the USA
    Hermannn Gmeiner School has been my alma mater for a long time. I have been extremely lucky to have studied here and have made lifelong friends in this school. FIGS has given a very unique perspective to me and my batch mates, due to its unique setting & approach to education in both curriculum and life skills. I wish the school all the best and will also be available to mentor any junior students if needed. 
    `,
    image: bharat_bansal,
  },

  {
    id: 5,
    title: "Subhadeep Sanyal",
    description: `Vice President - Omnivore Partners 
    I earned a B.Sc. (Hons) in Agriculture from CCS HAU, Hisar where I was the batch topper (2005-09). I completed my Masters degree from IIM Ahmedabad, where I specialized in agribusiness during the PGDM- ABM (2009-11). The reason why I had chosen SOS Hermann Gmeiner School over other schools in Faridabad was due to the ethos which the school had and still continues to have. The open culture and sense of collaboration among students and faculty, complete sense of freedom for a child's overall growth and the need to be rooted to one's core values were probably the greatest takeaways for me from the school.
    `,
    image: subhadeep,
  },

  {
    id: 6,
    title: "Karan Babbar",
    description: `Product Consultant - Accenture Service Private Limited 
    Excellent education is one where a child's interests are nurtured and built upon. Hermannn Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
    `,
    image: karan,
  },
];
export const sports_data = [
  {
    id: 1,
    title: "Sports",
    description: `Having an active lifestyle is key to living a healthy and fit life. To ensure that our students are not only just engrossed in studies, to promote a spirit of sportsmanship and camaraderie, we encourage the students to participate in regularly held intra and inter school events within and outside of the Hermannn Gmeiner School community. Taekwondo, Football, Badminton, Lawn Tennis, Table Tennis, Cricket, Chess and Yoga`,
  },
];

export const faculty_data = [
  {
    title: "Faculty & Staff",
    description: `Hermannn Gmeiner School Anangpur has a very experienced, knowledgeable, 
    well-qualified and dedicated Staff, so much so that they are conscious of their 
    responsibility of being the most important block in the foundation of the Nation 
    as well as the Society.`,
    description_image: [faculty_1, faculty_2],

    custom_class: ["faculty-img"],
    extra_description: [
      `Their self-discipline is an example in itself, which always drives them to work as 
    a well-knit team to make all possible endeavour together for the holistic growth 
    of students as well the institution. They are enthusiastic, dedicated, 
    conscientious and highly motivated to adapt to new innovative ideas and well 
    versed with modern education systems.`,
      `The valuable contribution of all the Faculty members is well manifested in the 
    spectacular achievements of our students in the scholastic as well as co-
    scholastic discipline.`,
      `The Teachers grouped under various subject-wise Faculties are put under the 
    guidance of the most experienced Faculty heads. All of them always put their 
    heads together to devise improvised methodology to deliver their best to 
    facilitate the young evolving minds. This significantly contributes in promoting 
    the problem solving skills of students to enable them to grow as useful citizens.`,
    ],
  },
];

export const events_data = [
  {
    title: "Children's day celebration",
    description: `"Every child is a different kind of flower and all together makes this world a beautiful garden" November 14 is celebrated as Children's day. This day is marking childhood and to make this event a memorable for our little ones. Celebration of the children's day focused on children and their enjoyment. All teachers introduce Jawaharlal Nehru's biography in their classes. Focus on his contribution to children. School organizes Drawing competition in school premises, give them topic like How to spread love and kindness, distribute chocolates and toffees as a token of love to the students. Students celebrates this day with a great enthusiasm.
    `,
    description_image: [annual_1],
  },
  {
    title: "Janmashtami celebration",
    description: `On Janam Ashtami we organize special assembly, group songs,cultural activities also tell the story about janmashtami. On this day children come in dressed like Radha and Krishna to added traditional touch to entire celebration. Students are participated in matki decorations and bansuri making activity. Teachers prepared students for cultural programs. All Staff members and students enjoyed this occasion with great enthusiasm.
    `,
    description_image: [janmashtmi],
  },

  {
    title: `Competitions – Poem Recitation & GK Quiz Competition`,
    description: `We organize competitions like Poem recitation competition &amp; G K quiz for students. School provides an
    opportunity to exhibit their talent through these events. Students come forward confidently and
    participate in these events conviction.
    Teachers prepare the students and motivate them to perform in front of various audience. These
    events gives opportunity to students to perform on various stages.`,
    description_image: [republic_1],
  },
  // {
  //   title: "",
  //   description: `Teachers prepare the students and give the topic of poem. 5 to 10 students are prepared by the teacher from each class.  Students come on stage and introduce themselves and recite the poem. 3 to 5 judges are judged the students and announce their positions.
  //   `,
  //   description_image: [republic_2],
  // },

  {
    title: `Gandhi Jayanti celebration`,
    description: `Gandhi Jayanti is celebrated on 2nd October every year to mark the birth anniversary of   Mohandas Karamchand Gandhi. He sacrificed his entire life as a freedom fighter and walked on the path of peace and non-violence. Students of our school enthusiastically take part in the Gandhi Jayanti event celebration. Children sing the famous devotional song of the Bapu "Ragupati Raghav Raja Ram" with garlands of beautiful flowers are placed on the portrait of Mahatma Gandhi. Small children celebrate this event by dressing up the same as the Gandhi ji as well as performing on patriotic songs`,
    description_image: [gandhijayanti],
  },
  {
    title: " Dussehra Celebration",
    description: `Ramlila literally means "Rama's play" is a performance of Ramayana epic in a series of scenes that include song, narration and dialogue. On the occasion, a cultural programme is organized like devotional songs, skits, drama and dances which are presented by the students. Children dressed up in different character from the epic of Ramayana. Teachers make them understand the significance of Dussehra by narrating and dramatizing the story of Ramayana on the stage. Our school is celebrated this event with pomp and show.`,
    description_image: [ramlila],
  },
  // {
  //   title: "Activity 2",
  //   description: `The students of 5th Grade made a model of lungs while their course of learning. The process of breathing was learned by them through hands-on experience which made their concept more clear about exhaling and inhaling done by our respiratory system.`,
  // },
  {
    title: `Mother's Day Celebration`,
    description: `“When you are looking at your mother, you are looking at the purest love you will ever know.”

    There is one extraordinary relationship in this life which is truly priceless in terms of love, dedication and devotion and that is of mother and child. To promote the bond between a mother and child one day is surely not enough to celebrate motherhood. However, every year, a day is specially marked for mothers all across the globe to pay homage to their selfless and unconditional love. So school celebrates this day with enthusiasm and great love. on this day activities and competitions are performed by the mothers in school premises like , making hair style ,Mehendi art, Matki race, solo dance , Bhajan and quiz.
    `,
    description_image: [motherday],
  },
  {
    title: "Independence Day Celebration",
    description: `School celebrates this national festival on same day, so that our children may remember the contribution of the freedom fighters in the society.
    School celebrates this event by different activities like small skits, patriotic songs, dance, poem and quiz.
    It is observed that our children should remember the history of our country and the sacrifice of our freedom fighters who fought for the Independence of the Nation. It inspires the young generation to serve the country
    `,
    description_image: [independence],
  },
  // {
  //   title: `Gk Quiz Competition`,
  //   description: `GK quiz competition is one of the most viral and engaging competition. In our school we have provided topics to all the students, topics were given before a week to all students. All class teachers shortlist the students. Rules of quiz introduced to the teams. Teams can discuss among themselves and then answer. Team who raise hands first will answer first .10 points for correct answer. There is no negative marking. GK quiz competition encourage the student to think on their own and to be responsible for the actions. It helps to develop reasoning, logicals skills and enhance the knowledge of surroundings, which is the most important part of our life.
  //   `,
  //   description_image: [gk],
  // },
  {
    title: `Republic Day Celebration`,
    description: `School is organized this national festival on the same day (26 Jan) of every year . All all the parents are invited to encourage the students and to provoke the nationality. Chief guest are invited to hoists the flag and encourage the students. Students are performed action songs, skits, dance and gave speech with a great enthusiasm. Students give tribute to our freedom fighters through their cultural program and dressed up as freedom fighters.
    `,
    description_image: [republic],
  },

  {
    title: `Christmas Day celebration`,
    description: `Inculcate the value of festivals and respect of the other religion, school celebrate all the festivals. In Christmas Day, school organization a cultural program, students sing carols, give speech and dressed up like Santa. Any one teacher also dressed up like Santa and distributes sweets among the students. Students decorate the Christmas tree with so many decoration materials. School organizes drawing competition in which students make Christmas tree and Carol balls.
    `,
    description_image: [christmas],
  },

  {
    title: `Save environment activity`,
    description: `To inculcate the value of clean environment and safe environment, School organises this activity. Students make banners and right slogans on environment, organise a rally for the social impact and make the understand the value of clean environment to the society. Students prepare skits on environment and present in front of local society to make them understand the value of cleanliness to save environment.
    `,
    description_image: [enviornment],
  },
  {
    title: `Sports Meet`,
    description: `Sports activities at school level works towards the child's overall benefit. If there is some sports activity in students daily activities then it would contribute toward his overall brain development and physical development. Sports are a crucial part of a student's growth and development. School help in the development of mental health and physical fitness of the body of the students. The school not only aims to improve a student's physical abilities but also instill a sense of good sportsmanship in them. `,
    description_image: [chess],
  },

  {
    title: "First Aid",
    description: `School-age children are generally at higher risk of being involved in accidents and sustaining injuries. Although many of these injuries are likely to be relatively minor, in some cases a child could sustain a serious or life-threatening injury in school premises., so school organizes for state training for the students. School shows them some videos and through the role plays, make them understand the dos and don'ts for the safety measurement.
    `,
    description_image: [aid],
  },
  // {
  //   title: `Social impact programs`,
  //   description: `In view of today's life, it is very important to make the society aware about the environment.  For this, school does programs to awaken the society, in which school presents skits by children and in that they teach the society how we have to keep our environment clean and healthy. make them understand we should plant trees, make our surroundings clean and use dustbins.
  //   Nowadays education is very important in society.  For this, our school makes a social impact programs for the parents, which teaches the parents to read, write and how we present ourselves in the society.
  //   `,
  //   description_image: [social_club],
  // },
];
